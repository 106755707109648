<template>
  <div class="product-category-create-page">
    <h2>Edit Product Category</h2>

    <product-category-form
      v-if="productCategory"
      v-model="productCategory"
      class="my-3"
    />

    <b-button @click="submit">Save</b-button>
  </div>
</template>

<script>
import ProductCategoryForm from '../components/ProductCategoryForm.vue'

export default {
  name: 'ProductCategoryEdit',

  components: {
    ProductCategoryForm
  },

  computed: {
    categoryId () {
      return this.$route.params.id
    }
  },

  data () {
    return {
      productCategory: null
    }
  },

  created () {
    this.fetch()
  },

  methods: {
    async fetch () {
      try {
        const resp = await this.$categories.get(this.categoryId)
        this.productCategory = resp.data.data
      }
      catch (e) {
        console.error(e.message)
      }
    },

    validate () {
      let valid = true

      return valid
    },

    async submit () {
      if (!this.validate()) {
        console.warn('Invalid form')
        return
      }

      try {
        const resp = await this.$categories.update(this.categoryId, this.productCategory)
        this.productCategory = resp.data.data
      }
      catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
